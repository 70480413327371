import { GetWebServiceMetadata, WebServiceInfo } from "../services/GetWebServices"
import { makeAutoObservable, observable } from "mobx"
import { RootStore } from "./RootStore"
import { formatJson } from "../utils/formatJson"

export class WebServiceStore {
    rootStore: RootStore
    webService?: WebServiceInfo

    constructor (rootStore: RootStore) {
        makeAutoObservable(this, {
            webService: observable.ref
        })

        this.rootStore = rootStore
    }

    setWebService = (ws: WebServiceInfo) =>
        this.webService = ws

    clearWebService = () =>
        this.webService = undefined

    loadWebService = async (name: string, isWebService: boolean) => {
        try {
            const ws = await GetWebServiceMetadata(name, isWebService)
            this.setWebService(ws)

            // populate default request/response
            const { webServiceRunnerStore: rs } = this.rootStore
            const fmt = formatJson
            rs.setDefaultRequestJson(fmt(ws.SampleJsonRequest))
            rs.setDefaultResponseJson(fmt(ws.SampleJsonResponse))
        } catch (e) {
            console.error("loadWebService error:", e)
        }
    }
}
