export function getCallStack() {
    const { stack } = new Error()
    const parts = (stack || "").split(/\n/)
    parts.splice(1, 1) // remove "at getCallStack() line"
    return parts.join("\n")
}

export function appendCallStack(error: any, stack?: any) {
    if (error !== null && error !== undefined && typeof error === "object" && typeof stack === "string") {
        let oldStack = error.stack
        if (oldStack) {
            oldStack += "\n"
        }

        error.stack = oldStack + stack
    }
}
