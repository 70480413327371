import LastErrorAlert from "../../components/LastErrorAlert"
import { Loading } from "@react-force/core"
import PageLayout from "../../components/PageLayout"
import React from "react"
import WebServiceTabView from "./WebServiceTabView"
import { observer } from "mobx-react"
import { useRootStore } from "../../contexts"

function WebServicePage() {
    const { callStatusStore: cs, routerStore, webServiceRunnerStore, webServiceStore } = useRootStore()
    const { name } = routerStore.routerState.params
    const { webService: ws } = webServiceStore
    const loaded = ws?.Name === name
    const loading = cs.callInProgress && !loaded
    const error = !cs.callInProgress && !loaded && cs.lastError

    // refresh, then restore the current request form data
    const handleRefresh = (ws && loaded) ? async () => {
        const { requestValue, requestJson } = webServiceRunnerStore
        await webServiceStore.loadWebService(ws.Name, ws.IsWebSocketService)
        webServiceRunnerStore.setRequestValue(requestValue)
        requestJson && webServiceRunnerStore.setRequestJson(requestJson)
    } : undefined

    return (
        <PageLayout title={name} onRefresh={handleRefresh}>
            {loading && <Loading />}
            {loaded && <WebServiceTabView />}
            {error && <LastErrorAlert title={"Error loading web service " + name}/> }
        </PageLayout>
    )
}

export default observer(WebServicePage)
