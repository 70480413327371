// rest services
export const apiUrl = "http://localhost:8337/"
export const apiUser = "bitrix"
export const apiKey = "bitrix"
export const apiTimeout = 10000

// websocket services
export const wsUrl = "ws://localhost:8338/"
export const wsUser = "root"
export const wsKey = "n/a"
export const wsVersion = "7.8.0.4"

