import { InputAdornment, InputBase, makeStyles } from "@material-ui/core"
import React, { useState } from "react"
import { Search } from "@material-ui/icons"

interface IProps {
    placeholder?: string
    value?: string
    setValue?: (newValue: string) => void
}

const useStyles = makeStyles(theme => ({
    inputRoot: {
        color: "inherit",
    },
    inputInput: {
        // padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            width: 120,
            "&:focus": {
                width: 200,
            },
        },
    },
}))

function SearchInput({ placeholder, setValue, value }: IProps) {
    const classes = useStyles()
    const [filter, setFilter] = useState(value)
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | undefined>()

    const handleChangeFilter = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const currentValue = e.currentTarget.value
        setFilter(currentValue)

        if (timeoutId) {
            clearTimeout(timeoutId)
        }

        if (setValue) {
            setTimeoutId(setTimeout(() => setValue(currentValue), 300))
        }
    }


    return (
        <InputBase
            placeholder={placeholder || "Search…"}
            classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
            }}
            value={filter}
            onChange={handleChangeFilter}
            startAdornment={
                <InputAdornment position="start">
                    <Search/>
                </InputAdornment>
            }
        />
    )
}

export default SearchInput
