import { Header as BaseHeader, HeaderTitle } from "@react-force/core"
import { BackButton } from "./BackButton"
import FavoritesButton from "./FavoritesButton"
import HeaderMenu from "./HeaderMenu"
import HomeButton from "./HomeButton"
import React from "react"
import RecentButton from "./RecentButton"
import RefreshButton from "./RefreshButton"
import SearchInput from "./SearchInput"
import { observer } from "mobx-react-lite"

export interface IProps {
    backButton?: boolean
    homeButton?: boolean
    title?: React.ReactElement | string
    onRefresh?: () => void
    search?: string
    setSearch?: (newFilter: string) => void
    searchPlaceholder?: string
}

function CustomHeader(props: IProps) {
    const backButton = props.backButton === undefined || props.backButton
    const homeButton = props.homeButton === undefined || props.homeButton
    const { onRefresh, search, setSearch, searchPlaceholder } = props

    return (
        <BaseHeader>
            {backButton && <BackButton />}
            <HeaderTitle>
                {props.title}
            </HeaderTitle>

            {setSearch &&
                <SearchInput
                    value={search}
                    setValue={setSearch}
                    placeholder={searchPlaceholder}
                />}

            {onRefresh && <RefreshButton onClick={onRefresh}/>}
            <FavoritesButton />
            <RecentButton />
            {homeButton && <HomeButton />}
            <HeaderMenu />
        </BaseHeader>
    )
}

export default observer(CustomHeader)
