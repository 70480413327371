// Request form builds JSON structures
// It is shared by rest services and web socket services

import { Card, CardContent, CardHeader, FormControlLabel, Switch } from "@material-ui/core"
import React, { useState } from "react"
import FavoriteButton from "../FavoriteButton"
import { HorizontalContainer } from "@react-force/core"
import { JsonEditor } from "../JsonViewer"
import ParameterForm from "../ParameterForm"
import ReactJsonView from "react-json-view"
import WebServiceTypeIndicator from "../WebServiceTypeIndicator"
import { observer } from "mobx-react"
import { stringifyValue } from "../../utils/stringifyValue"
import { useMeasure } from "react-use"
import { useRootStore } from "../../contexts"

interface IProps {
    value?: string
    onChange?: (json: string) => void
}

function RequestForm(props: IProps) {
    const [formRef, { height: formHeight }] = useMeasure<any>()
    const [divRef, { height: divHeight }] = useMeasure<any>()
    const [editableJson, setEditableJson] = useState(false)
    const { webServiceRunnerStore, webServiceStore: ws } = useRootStore()
    const { requestJson, setRequestJson, requestValue, setRequestValue } = webServiceRunnerStore

    const loading = !ws.webService || !ws.webService.DtoTypes || !ws.webService.DtoTypes.length
    if (loading) {
        return null
    }

    const handleUpdateValue = (name: string, value: Object | null) => {
        let jsonRequest = stringifyValue(value)

        setRequestValue(value)
        setRequestJson(jsonRequest)
    }

    const handleUpdateJson = (requestJson: string) => {
        setRequestJson(requestJson)
        try {
            let requestValue = JSON.parse(requestJson)
            setRequestValue(requestValue)
        } catch {
            // ignore malformed JSON
        }
    }

    const handleEdit = (edit: { updated_src: any }) => {
        handleUpdateValue("", edit.updated_src)
    }

    const isWebSocket = ws.webService?.IsWebSocketService || false
    const dto = ws.webService?.DtoTypes && ws.webService.DtoTypes[0]
    if (!dto || dto.IsResponse) {
        const serviceName = ws?.webService?.Name || ""
        return (
            <Card variant="outlined" style={{ marginRight: "0.5em" }} >
                <CardHeader title={serviceName + " service has no parameters"}
                    style={{ paddingBottom: "1em" }}
                    action={
                        <div style={{ marginRight: "1em", paddingTop: "0.3em" }}>
                            <FavoriteButton name={serviceName} isWebSocket={isWebSocket} />
                            <WebServiceTypeIndicator isWebSocket={isWebSocket} />
                        </div>
                    }
                />
            </Card>
        )
    }

    const handleEditableJsonChanged = (e: React.ChangeEvent<HTMLInputElement>) =>
        setEditableJson(e.target.checked)

    return (
        <HorizontalContainer>
            <div style={{ overflow: "auto", width: "50%", height: formHeight + 2, maxHeight: 500, marginRight: "0.5em" }} ref={divRef}>
                <Card variant="outlined" style={{ marginRight: "0.5em" }} ref={formRef}>
                    <CardHeader
                        title={dto.Name}
                        style={{ paddingBottom: 0 }}
                        action={
                            <div style={{ marginRight: "1em", paddingTop: "0.3em" }}>
                                <FavoriteButton name={dto.Name} isWebSocket={isWebSocket} />
                                <WebServiceTypeIndicator isWebSocket={isWebSocket} />
                            </div>
                        }
                    />
                    <CardContent>
                        <ParameterForm
                            name="root request name is unused"
                            value={requestValue}
                            update={handleUpdateValue}
                            structure={ws.webService?.DtoTypes!}
                            dtoClass={dto}
                        />
                    </CardContent>
                </Card>
            </div>
            <Card variant="outlined" style={{ overflow: "auto", maxHeight: 500, height: divHeight, width: "50%", marginRight: "0.5em" }}>
                <CardHeader title="Request body" style={{ paddingBottom: 0 }} action={
                    <div style={{ paddingTop: "0.4em" }}>
                        <FormControlLabel
                            control={
                                <Switch
                                    name="EditableJson"
                                    value={editableJson}
                                    onChange={handleEditableJsonChanged}
                                />}
                            label="Structured editor"
                        />
                    </div>}
                />
                <CardContent>
                    {editableJson ?
                        <ReactJsonView // interactive JSON editor
                            style={{ padding: 0, margin: 0, fontSize: 14 }}
                            name={dto!.Name}
                            src={requestJson ? JSON.parse(requestJson) : requestValue}
                            indentWidth={2}
                            onEdit={handleEdit}
                            onAdd={handleEdit}
                            onDelete={handleEdit}
                        /> :
                        <JsonEditor
                            json={requestJson}
                            onJsonChange={handleUpdateJson}
                        />
                    }
                </CardContent>
            </Card>
        </HorizontalContainer>
    )
}

export default observer(RequestForm)
