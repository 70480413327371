import JsonClient, { IJsonClient } from "json-services-client"
import { wsKey, wsUrl, wsUser, wsVersion } from "./constants"
import { UltimaCredentials } from "./UltimaCredentials"

let traceCallbacks = undefined as (ITraceCallbacks | undefined)

export let jsonClient = createJsonClient(wsUrl, wsUser, wsKey, wsVersion)

function createJsonClient(url: string, userName: string, password: string, kernelVersion: string) {
    const jsonClient = new JsonClient(url)

    jsonClient.credentials = new UltimaCredentials({
        userName,
        password,
        kernelVersion,
    })

    updateTraceCallbacks(jsonClient, traceCallbacks)
    return jsonClient
}

export function updateWebSocketSettings(settings: {
    wsUrl: string
    wsUser: string
    wsKey: string
    wsVersion: string
}) {
    jsonClient = createJsonClient(
        settings.wsUrl,
        settings.wsUser,
        settings.wsKey,
        settings.wsVersion
    )
}

export interface ITraceCallbacks {
    startLoading: () => void
    stopLoading: () => void
    traceMessage: (e: { isOutcoming: boolean, data: string }) => void
}

export function setupTraceCallbacks(callbacks: ITraceCallbacks) {
    console.log("websocket trace callbacks are set up, jsonClient is recreated", callbacks)

    traceCallbacks = callbacks
    updateTraceCallbacks(jsonClient, traceCallbacks)
}

function updateTraceCallbacks(jsonClient: IJsonClient, traceCallbacks: ITraceCallbacks | undefined) {
    jsonClient.traceMessage = () => {}

    if (traceCallbacks) {
        jsonClient.traceMessage = (e: { isOutcoming: boolean, data: string }) => {
            if (e.isOutcoming) {
                traceCallbacks?.startLoading()
            } else {
                traceCallbacks?.stopLoading()
            }
            traceCallbacks?.traceMessage(e)
        }
    }
}
