import * as srp from "secure-remote-password/client"
import * as uuid from "uuid"
import { AuthRequest } from "json-services-client"
import { ICredentials } from "json-services-client"
import { IJsonClient } from "json-services-client"

const defaultKernelVersion = "7.8.0.0"

export class UltimaCredentials implements ICredentials {
    constructor(private credentials: {
        userName: string
        password?: string
        loginAs?: string
        sessionId?: string
        kernelVersion?: string
    }) {
    }

    public async authenticate(client: IJsonClient): Promise<string> {
        if (!this.credentials.password) {
            return this.authenticateUsingSessionId(client)
        }

        // SRP authentication is done in two steps
        // step1: -> I, A, session
        const loginSessionId = uuid.v4()
        const ephemeral = srp.generateEphemeral()
        const req1 = new AuthRequest()
        req1.Parameters = {
            ClientPublicEphemeral: ephemeral.public,
            LoginSessionID: loginSessionId,
            UserName: this.credentials.userName,
            KernelVersion: this.credentials.kernelVersion ?? defaultKernelVersion,
            StepNumber: "1",
        }

        // step1: <- s, B
        const step1 = await client.call(req1)

        // make sure that salt is just a hexadecimal string
        const saltSignature = "$SRP6a1$"
        let salt = step1.Parameters.Salt
        if (salt.startsWith(saltSignature)) {
            salt = salt.substring(saltSignature.length)
        }

        // client derives session
        const userName = this.credentials.userName.toLowerCase()
        const privateKey = srp.derivePrivateKey(salt, userName, this.credentials.password)
        const clientSession = srp.deriveSession(ephemeral.secret, step1.Parameters.ServerPublicEphemeral, salt, userName, privateKey)

        // step2: -> M1, session
        const req2 = new AuthRequest()
        req2.Parameters = {
            ClientSessionProof: clientSession.proof,
            LoginSessionID: loginSessionId,
            KernelVersion: this.credentials.kernelVersion ?? defaultKernelVersion,
            StepNumber: "2",
        }

        // additional session parameters (MachineName, OsUserName, etc. are not available in Javascript)
        if (this.credentials.loginAs) {
            req2.Parameters.LoginAs = this.credentials.loginAs
        }

        // step2: <- M2
        const step2 = await client.call(req2)
        srp.verifySession(ephemeral.public, clientSession, step2.Parameters.ServerSessionProof)
        return step2.SessionId
    }

    private async authenticateUsingSessionId(client: IJsonClient): Promise<string> {
        // tslint:disable-next-line:no-console
        console.warn("simple authentication: sessionId = " + this.credentials.sessionId)

        // simple authentication to enable refreshing web application using F5 key
        const req = new AuthRequest()
        req.Parameters = {
            KernelVersion: this.credentials.kernelVersion ?? defaultKernelVersion,
            UserName: this.credentials.userName,
            AppServerSessionID: this.credentials.sessionId || "",
            StepNumber: "0",
        }

        const resp = await client.call(req)
        return resp.SessionId
    }
}
