// Tracks remote call execution progress
// Stores the last error message which can be displayed in a popup message

import { RootStore } from "./RootStore"
import { makeAutoObservable } from "mobx"
import { setupLoadingInterceptors } from "../services/request"
import { setupTraceCallbacks } from "../services/webSockets"

export interface IJsonServiceMessage {
    id?: string
    timeStamp?: Date
    isOutcoming: boolean
    data: string
}

export class CallStatusStore {
    rootStore: RootStore
    callLevel = 0
    counter = 0
    lastError?: any
    lastErrorClosed = false
    jsonMessages: IJsonServiceMessage[] = []

    constructor(rootStore: RootStore) {
        makeAutoObservable(this)
        this.rootStore = rootStore

        // display loading indicator on axios calls
        setupLoadingInterceptors({
            startLoading: this.startCall,
            stopLoading: this.stopCall,
            setLastError: this.setLastError,
            clearLastError: this.clearLastError,
        })

        // display loading indicator on json-rpc calls
        setupTraceCallbacks({
            startLoading: this.startCall,
            stopLoading: this.stopCall,
            traceMessage: this.addJsonMessage,
        })
    }

    addJsonMessage = (e: IJsonServiceMessage) => {
        e.id = `${this.counter++}`
        e.timeStamp = new Date()
        this.jsonMessages.unshift(e)
    }

    startCall = () => {
        this.callLevel++
        if (this.callLevel > 5) {
            throw new Error("Too many parallel calls. Most likely, it's a circular update caused by autorun.")
        }
    }

    stopCall = () => this.callLevel--
    get callInProgress() {
        return this.callLevel > 0
    }

    clearLastError = () => this.lastError = undefined
    setLastError = (e: any) => {
        this.lastError = e
        this.lastErrorClosed = false
    }

    setLastErrorClosed = (c: boolean) =>
        this.lastErrorClosed = c
}
