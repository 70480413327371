import {
    apiKey,
    apiTimeout,
    apiUser,
    wsKey,
    wsUrl,
    wsUser,
    wsVersion,
} from "../services/constants"
import { makeAutoObservable, toJS } from "mobx"
import { RootStore } from "./RootStore"
import { updateApiSettings } from "../services/request"
import { updateWebSocketSettings } from "../services/webSockets"

export interface ISettings {
    // rest services
    apiUrl: string
    apiUser: string
    apiKey: string
    apiTimeout: number

    // websocket services
    wsKey: string
    wsUrl: string
    wsUser: string
    wsVersion: string
}

export class SettingsStore {
    rootStore: RootStore
    settings = {
        apiUrl: window.location.origin,
        apiKey,
        apiUser,
        apiTimeout,
        wsKey,
        wsUrl,
        wsUser,
        wsVersion,
    }

    constructor(rootStore: RootStore) {
        makeAutoObservable(this)
        this.rootStore = rootStore
        this.reset()
        this.loadFromStorage()
    }

    reset = () => {
        return this.set({
            apiUrl: window.location.origin,
            apiKey,
            apiUser,
            apiTimeout,
            wsKey,
            wsUrl,
            wsUser,
            wsVersion,
        })
    }

    set = (settings: ISettings) => {
        this.settings = settings

        // recreate the API client
        updateApiSettings(settings)
        updateWebSocketSettings(settings)
        return this.settings
    }

    loadFromStorage = () => {
        const settings = localStorage.getItem("webService.settings")
        if (settings) {
            const parsed = JSON.parse(settings) as ISettings
            this.set(parsed)
        }
    }

    saveToStorage = () => {
        localStorage.setItem("webService.settings", JSON.stringify(toJS(this.settings)))
    }
}
