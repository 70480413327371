import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core"
import React from "react"
import WebServiceTableRow from "./WebServiceTableRow"
import WebServiceTypeFilter from "./WebServiceTypeFilter"
import { observer } from "mobx-react"
import { useRootStore } from "../../contexts"

function WebServiceTable() {
    const { webServiceListStore: ws } = useRootStore()
    if (!ws.webServices || !ws.webServices.length) {
        return null
    }

    return (
        <Table style={{ marginTop: "1em", tableLayout: "fixed" }}>
            <TableHead>
                <TableRow>
                    <TableCell>
                        Name
                    </TableCell>
                    <TableCell align="center">
                        <WebServiceTypeFilter
                            value={ws.webServiceType}
                            onClick={ws.cycleWebServiceType}
                        />
                    </TableCell>
                    <TableCell>Group name</TableCell>
                    <TableCell>Comments</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {ws.filteredWebServices.map(w => (
                    <WebServiceTableRow
                        key={w.ID}
                        name={w.Name}
                        groupName={w.GroupName}
                        devComments={w.DevComments}
                        isArray={w.IsArrayResponse}
                        isWebSocket={w.IsWebSocketService}
                        reqCount={w.RequestParameterCount}
                        resCount={w.ResponseParameterCount}
                    />
                ))}
            </TableBody>
        </Table>
    )
}

export default observer(WebServiceTable)
