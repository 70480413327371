import { Restore, Star } from "@material-ui/icons"
import { Tab, Typography } from "@material-ui/core"
import { TabContext, TabList, TabPanel } from "@material-ui/lab"
import { FavoriteTab } from "../../stores/OptionsStore"
import React from "react"
import { WebServiceList } from "./WebServiceList"
import { observer } from "mobx-react"
import { useRootStore } from "../../contexts"

function FavoriteList() {
    const { favoritesStore: fs, optionsStore: os, recentStore: rs } = useRootStore()

    const handleChangeTab = (_: unknown, tab: FavoriteTab) => os.setFavoriteTab(tab)
    const hasFavorites = fs.favorites && fs.favorites.length > 0
    const hasRecent = rs.recentItems && rs.recentItems.length > 0

    return (
        <TabContext value={os.favoriteTab}>
            <TabList onChange={handleChangeTab}>
                <Tab label={<><Star />Favorites</>} value="Favorites"/>
                <Tab label={<><Restore />Recent items</>} value="Recent"/>
            </TabList>
            <TabPanel value="Favorites" style={{ padding: "1em" }}>
                {hasFavorites ?
                    <WebServiceList
                        items={fs.favorites}
                        deleteItem={fs.removeFavorite}
                        deleteButtonText="Remove from favorites"
                    /> :
                    <Typography>
                        There are currently no favorites.<br/>
                        Click on the ☆ icon to add one.
                    </Typography>
                }
            </TabPanel>
            <TabPanel value="Recent" style={{ padding: "1em" }}>
                {hasRecent ?
                    <WebServiceList
                        items={rs.recentItems}
                        deleteItem={rs.removeRecentItem}
                        deleteButtonText="Remove recent item"
                    /> :
                    <Typography>
                        There are currently no recent items.<br/>
                        Click on a webservice link to add one.
                    </Typography>
                }
            </TabPanel>
        </TabContext>
    )
}

export default observer(FavoriteList)
