// Represents a single scalar parameter input box

import { FormControl, InputLabel, MenuItem, Select, TextField } from "@material-ui/core"
import { ScalarType, parseScalarValue } from "../../utils/parseScalarValue"
import React from "react"
import { isValidDate } from "../../utils/isValidDate"
import { useId } from "react-id-generator"
import { useMountEffect } from "../../utils/useMountEffect"

interface IProps {
    name: string
    type: string
    value: Object | null
    update: (name: string, value: Object | null) => void
}

function ScalarParameterBox(props: IProps) {
    const [inputId] = useId()
    const updateValue = (value: string) => {
        if (props.update) {
            const parsedValue = parseScalarValue(value, props.type as ScalarType)
            props.update(props.name, parsedValue)
        }
    }

    useMountEffect(() => {
        updateValue((props.value || "").toString())
    })

    const handleInputChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = e.currentTarget.value
        updateValue(value)
    }

    const handleSelectChange = (e: React.ChangeEvent<{ name?: string, value: any }>) => {
        const value = e.target.value // e.name //e.currentTarget.value?
        updateValue(value)
    }

    let value = props.value
    let placeholder = props.type
    if (props.type === "DateTime" || props.type === "DateTime?") {
        placeholder += " — yyyy-mm-dd hh:mm:ss"
        if (isValidDate(value)) {
            let strValue = value.toISOString().replace("T", " ") // strip 'T' between the date and time parts
            strValue = strValue.substring(0, strValue.length - 5)  // strip the trailing time zone part
            value = strValue
        }
    }

    if (props.type === "bool" || props.type === "boolean") {
        // <FormHelperText>{placeholder}</FormHelperText>
        return (
            <FormControl fullWidth={true} margin="dense">
                <InputLabel id={inputId}>{props.name}</InputLabel>
                <Select
                    labelId={inputId}
                    value={value ? "true" : "false"}
                    onChange={handleSelectChange}
                    placeholder={placeholder}
                >
                    <MenuItem value="true">True</MenuItem>
                    <MenuItem value="false">False</MenuItem>
                </Select>
            </FormControl>
        )
    } else if (value === "0" || value === 0) {
        // <FormHelperText>{placeholder}</FormHelperText>
        return (
            <FormControl fullWidth={true} margin="dense">
                <TextField
                    label={props.name}
                    placeholder={placeholder}
                    value={value.toString()}
                    onChange={handleInputChange}
                />
            </FormControl>
        )
    } else {
        // <FormHelperText>{placeholder}</FormHelperText>
        return (
            <FormControl fullWidth={true} margin="dense">
                <TextField
                    label={props.name}
                    value={(value || "").toString()}
                    onChange={handleInputChange}
                    placeholder={placeholder}
                />
            </FormControl>
        )
    }
}

export default ScalarParameterBox
