import { IconButton, Tooltip } from "@material-ui/core"
import React from "react"
import { Refresh } from "@material-ui/icons"

interface IProps {
    onClick: () => void
}

function RefreshButton({ onClick }: IProps) {
    return (
        <Tooltip title="Refresh page data">
            <IconButton onClick={onClick} color="inherit">
                <Refresh />
            </IconButton>
        </Tooltip>
    )
}

export default RefreshButton
