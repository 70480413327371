import { Alert, AlertTitle } from "@material-ui/lab"
import React, { CSSProperties } from "react"
import { Link } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../contexts"

interface IProps {
    title?: string
    style?: CSSProperties
}

function LastErrorAlert({ title, style }: IProps) {
    const { callStatusStore, routerStore } = useRootStore()
    const { lastError } = callStatusStore
    if (!lastError) {
        return null
    }

    const handleClick = () => {
        callStatusStore.setLastErrorClosed(true)
        routerStore.goTo("lastError")
    }

    return (
        <Alert severity="error" style={style}>
            <AlertTitle>{title || lastError}</AlertTitle>
            <Link component="button" onClick={handleClick}>
                {`${lastError?.message || lastError || "More"}`}...
            </Link>
        </Alert>
    )
}

export default observer(LastErrorAlert)
