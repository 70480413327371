import React, { useState } from "react"
import { Table, TableBody, TableCell, TableHead, TableRow, makeStyles } from "@material-ui/core"
import MessageView from "./MessageView"
import PageLayout from "../../components/PageLayout"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../contexts"

// timestamp formatting
const format = new Intl.DateTimeFormat("UK", {
    timeStyle: "medium",
    // year:"numeric",
    // month: "2-digit",
    // day: "2-digit",
    // hour: "2-digit",
    // minute: "2-digit",
    // second: "2-digit"
} as any)

// fixed-layout table that fits entire screen
const useStyles = makeStyles({
    table: {
        tableLayout: "fixed",
    },
    cell: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
})

function MessageLogPage() {
    const { callStatusStore } = useRootStore()
    const classes = useStyles()
    const [json, setJson] = useState("")
    const [open, setOpen] = useState(false)

    const handleClick = (json: string) => () => {
        setJson(json)
        setOpen(true)
    }

    const handleClose = () => setOpen(false)

    return (
        <PageLayout title="Web socket message log">
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ width: "2em" }}>&nbsp;</TableCell>
                        <TableCell className={classes.cell} style={{ width: "8em" }}>Timestamp</TableCell>
                        <TableCell className={classes.cell} style={{ width: "*" }}>Message</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {callStatusStore.jsonMessages.map(m => (
                        <TableRow key={m.id}>
                            <TableCell>{m.isOutcoming ? "→" : "←"}</TableCell>
                            <TableCell className={classes.cell}>{m.timeStamp && format.format(m.timeStamp)}</TableCell>
                            <TableCell className={classes.cell} onClick={handleClick(m.data)}>
                                <pre style={{ fontSize: 14, margin: 0 }}>{m.data}</pre>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <MessageView json={json} open={open} onClose={handleClose}/>
        </PageLayout>
    )
}

export default observer(MessageLogPage)
