import { Drawer, LinearProgress } from "@material-ui/core"
import { HorizontalContainer, ScrollingContainer, ViewVerticalContainer } from "@react-force/core"
import React, { ReactNode, useEffect } from "react"
import ErrorPopupMessage from "./ErrorPopupMessage"
import FavoriteList from "../FavoriteList"
import Header from "../Header"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../contexts"

export interface IProps {
    backButton?: boolean
    homeButton?: boolean
    title?: string
    onRefresh?: () => void
    search?: string
    setSearch?: (f: string) => void
    searchPlaceholder?: string
    children?: ReactNode | ReactNode[]
}

function PageLayout(props: IProps) {
    // use page title to produce readable browser history
    useEffect(() => {
        if (document && props.title) {
            document.title = props.title
        }
    })

    const { callStatusStore, optionsStore } = useRootStore()

    let progress = <LinearProgress variant="indeterminate" />
    if (!callStatusStore.callInProgress) {
        progress = <LinearProgress variant="determinate" value={0} style={{ opacity: 0 }} />
    }

    const hideDrawer = () =>
        optionsStore.setFavorites(false)

    return (
        <ViewVerticalContainer minHeight={0}>
            <Header {...props} />
            {progress}

            <HorizontalContainer minHeight={0}>
                <ScrollingContainer p={2} flex="1">
                    {props.children}
                </ScrollingContainer>

                <Drawer
                    anchor="right"
                    open={optionsStore.showFavorites}
                    onClose={hideDrawer}
                >
                    <FavoriteList />
                </Drawer>
            </HorizontalContainer>


            <ErrorPopupMessage />
        </ViewVerticalContainer>
    )
}

export default observer(PageLayout)
