import { GetWebServiceMetadata, WebServiceInfo } from "../services/GetWebServices"
import { makeAutoObservable, observable } from "mobx"
import { RootStore } from "./RootStore"

const ServiceTypes = ["rest", "websocket", undefined]

type ServiceTypeFilter = "rest" | "websocket" | undefined

export class WebServiceListStore {
    rootStore: RootStore
    webServiceFilter?: string = undefined
    webServiceType: ServiceTypeFilter = undefined
    webServices: WebServiceInfo[] = []
    lastWebService?: [name: string, isWebSocket: boolean] = undefined

    constructor (rootStore: RootStore) {
        makeAutoObservable(this, {
            webServices: observable.ref
        })

        this.rootStore = rootStore
    }

    setWebServices = (ws: WebServiceInfo[]) => {
        this.webServices = ws.sort((a, b) => {
            if (a.IsWebSocketService !== b.IsWebSocketService) {
                return a.IsWebSocketService ? 1 : -1
            } else {
                return a.Name > b.Name ? 1 : -1
            }
        })
    }

    clearWebServices = () =>
        this.webServices = []

    loadWebServices = async () => {
        try {
            const result = await GetWebServiceMetadata()
            this.setWebServices(result)
        } catch (e) {
            console.error("loadWebServices", e)
            this.clearWebServices()
        }
    }

    setWebServiceFilter = (filter?: string) =>
        this.webServiceFilter = filter

    setWebServiceType = (type: ServiceTypeFilter) =>
        this.webServiceType = type

    cycleWebServiceType = () => {
        const type = this.webServiceType
        const next = (ServiceTypes.findIndex(p => p === type) + 1) % ServiceTypes.length
        this.setWebServiceType(ServiceTypes[next] as ServiceTypeFilter)
    }

    get filteredWebServices() {
        let result = this.webServices
        if (result === undefined || result.length === 0) {
            return []
        }

        if (this.webServiceFilter) {
            const lowerFilter = this.webServiceFilter.toLowerCase()
            result = result.filter(ws => (
                `${ws.Name} ${ws.GroupName} ${ws.DevComments}`.toLowerCase().indexOf(lowerFilter) >= 0
            ))
        }

        if (this.webServiceType) {
            result = result.filter(ws => ws.IsWebSocketService === (this.webServiceType === "websocket"))
        }

        return result
    }

    clearLastWebService = () => this.lastWebService = undefined

    setLastWebService = (name: string, isWebSocket: boolean) =>
        this.lastWebService = [name, isWebSocket]
}
