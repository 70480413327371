import { makeAutoObservable, toJS } from "mobx"
import { IServiceItem } from "./FavoritesStore"
import { RootStore } from "./RootStore"

export class RecentStore {
    rootStore: RootStore
    recentItems: IServiceItem[] = []

    constructor (rootStore: RootStore) {
        makeAutoObservable(this)
        this.rootStore = rootStore
        this.loadRecentItems()
    }

    saveRecentItems = () => {
        const data = JSON.stringify(toJS(this.recentItems))
        localStorage.setItem("webService.recentItems", data)
    }

    loadRecentItems = () => {
        const data = localStorage.getItem("webService.recentItems")
        if (data) {
            this.recentItems = JSON.parse(data) as IServiceItem[]
        }
    }

    addRecentItem = (name: string, isWebSocket: boolean) => {
        if (this.isRecentItem(name, isWebSocket)) {
            return
        }

        this.recentItems.push({
            name,
            isWebSocket,
        })

        this.recentItems = this.recentItems.sort((a, b) => {
            const [an, bn] = [`${a.isWebSocket ? "!": ""}${a.name}`, `${b.isWebSocket ? "!": ""}${b.name}`]
            return an > bn ? 1 : -1
        })

        this.saveRecentItems()
    }

    removeRecentItem = (name: string, isWebSocket: boolean) => {
        this.recentItems = this.recentItems.filter(i => i.name !== name || i.isWebSocket !== isWebSocket)
        this.saveRecentItems()
    }

    isRecentItem = (name: string, isWebSocket: boolean) =>
        this.recentItems.find(i => i.name === name && i.isWebSocket === isWebSocket)

    clearRecentItems = () => {
        this.recentItems = []
        this.saveRecentItems()
    }
}
