import PageLayout from "../components/PageLayout"
import React from "react"

function NotFoundPage() {
    return (
        <PageLayout title="Page not found">
        </PageLayout>
    )
}

export default NotFoundPage
