// Describes the supported routes, their parameters and event handlers

import {
    RouterState,
    RouterStore,
    // createRouterState
} from "mobx-state-router"
import { RootStore } from "./RootStore"

// const loginState = createRouterState("login")

// // router state change handler that regirects a user to the login page
// const checkForUserLoggedIn = async(
//     fromState: RouterState,
//     toState: RouterState,
//     routerStore: RouterStore
// ) => {
//     const { rootStore } = routerStore.options
//     const { authStore } = rootStore as RootStore

//     console.log("Checking whether the user is logged in...")
//     if (!authStore.user) {
//         console.warn("Not logged in, redirecting to the login page.")
//         authStore.setLoginRedirect(toState)
//         return loginState
//     }
// }

// router state change handler that loads the web service metadata
const loadWebService = async (
    fromState: RouterState,
    toState: RouterState,
    routerStore: RouterStore
) => {
    const { rootStore } = routerStore.options
    const { webServiceStore } = rootStore as RootStore
    const { params } = toState

    const name = params.name as string
    const isWebSocket = toState.routeName === "webSocketService"
    const loadedName = webServiceStore.webService?.Name
    const loadedWebSocket = webServiceStore.webService?.IsWebSocketService || false

    // load web service metadata if not loaded yet
    if (loadedName !== name || loadedWebSocket !== isWebSocket) {
        console.warn("Should reload", {
            wsName: webServiceStore.webService?.Name,
            wsWebService: webServiceStore.webService?.IsWebSocketService,
            name,
            isWebSocket,
        })
        webServiceStore.loadWebService(name, isWebSocket)
    }
}

export const routes = [
    {
        name: "home",
        pattern: "/",
        onEnter: async (
            fromState: RouterState,
            toState: RouterState,
            routerStore: RouterStore
        ) => {
            const { rootStore } = routerStore.options
            const { webServiceListStore } = rootStore as RootStore

            // load web services if not loaded yet
            if (!webServiceListStore.webServices?.length) {
                webServiceListStore.loadWebServices()
            }
        }
    },
    {
        name: "login",
        pattern: "/login",
    },
    {
        name: "settings",
        pattern: "/settings",
    },
    {
        name: "restService",
        pattern: "/rest/:name",
        onEnter: loadWebService,
    },
    {
        name: "webSocketService",
        pattern: "/ws/:name",
        onEnter: loadWebService,
    },
    {
        name: "messageLog",
        pattern: "/messageLog",
    },
    {
        name: "lastError",
        pattern: "/error",
    },
    {
        name: "appServer",
        pattern: "/content/index.html",
        beforeEnter: async (
            fromState: RouterState,
            toState: RouterState,
            routerStore: RouterStore
        ) => {
            console.log("Hosted by ULTIMA application server.")
            routerStore.goTo("home")
        }
    },
    {
        name: "notFound",
        pattern: "/404",
    },
]
