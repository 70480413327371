// Combines all application stores in one, including the router store
// This store can be imported using the useRootStore() hook

import { RouterStore, createRouterState } from "mobx-state-router"
import { CallStatusStore } from "./CallStatusStore"
import { FavoritesStore } from "./FavoritesStore"
import { OptionsStore } from "./OptionsStore"
import { RecentStore } from "./RecentStore"
import { SettingsStore } from "./SettingsStore"
import { WebServiceListStore } from "./WebServiceListStore"
import { WebServiceRunnerStore } from "./WebServiceRunnerStore"
import { WebServiceStore } from "./WebServiceStore"
import { WebSocketServiceRunnerStore } from "./WebSocketServiceRunnerStore"
import { routes } from "./routes"

const notFound = createRouterState("notFound")

export class RootStore {
    callStatusStore = new CallStatusStore(this)
    favoritesStore = new FavoritesStore(this)
    optionsStore = new OptionsStore(this)
    recentStore = new RecentStore(this)
    settingsStore = new SettingsStore(this)
    webServiceListStore = new WebServiceListStore(this)
    webServiceRunnerStore = new WebServiceRunnerStore(this)
    webServiceStore = new WebServiceStore(this)
    webSocketServiceRunnerStore = new WebSocketServiceRunnerStore(this)

    // pass root store as an option to the router store
    public routerStore = new RouterStore(routes, notFound, {
        rootStore: this
    })
}
