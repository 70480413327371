// Represents a panel with a title, contents and an optional clickable link

import { Card, CardContent, Typography } from "@material-ui/core"
import React from "react"

interface IProps {
    title: string
    linkText?: string
    linkHandler?: () => void
    children: React.ReactElement | React.ReactElement[]
}

function ParameterPanel(props: IProps) {
    let handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        if (props.linkHandler != null) {
            props.linkHandler()
        }
    }

    return (
        <Card style={{ marginTop: "1em" }} variant="outlined">
            <CardContent>
                {
                    props.linkText && props.linkHandler &&
                    <a href={"#" + props.linkText} onClick={handleClick} style={{
                        cursor: "pointer",
                        float: "right",
                        marginTop: "5px",
                        textDecoration: "none"
                    }}>{props.linkText}</a>
                }
                <Typography variant="h6">{props.title}</Typography>
                {props.children}
            </CardContent>
        </Card>
    )
}

export default ParameterPanel
