import { IconButton, InputAdornment, TextField } from "@material-ui/core"
import React, { useState } from "react"
import LastErrorAlert from "../../components/LastErrorAlert"
import PageLayout from "../../components/PageLayout"
import { Refresh } from "@material-ui/icons"
import WebServiceTable from "./WebServiceTable"
import { observer } from "mobx-react"
import { useRootStore } from "../../contexts"

function HomePage() {
    const { callStatusStore, settingsStore, webServiceListStore: ws } = useRootStore()
    const [baseUrl, setBaseUrl] = useState(settingsStore.settings.apiUrl)

    const handleSetBaseUrl = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        setBaseUrl(e.currentTarget.value)

    const handleRefresh = () => {
        // update base url as needed
        if (settingsStore.settings.apiUrl !== baseUrl) {
            settingsStore.set({
                ...settingsStore.settings,
                apiUrl: baseUrl,
            })

            settingsStore.saveToStorage()
        }

        ws.loadWebServices()
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            handleRefresh()
        }
    }

    return (
        <PageLayout
            title="Ultima Web Services"
            backButton={false}
            homeButton={false}
            onRefresh={ws.loadWebServices}
            search={ws.webServiceFilter}
            setSearch={ws.setWebServiceFilter}
            searchPlaceholder="Search..."
        >
            <TextField
                fullWidth={true}
                label="Web server address"
                margin="normal"
                value={baseUrl}
                onChange={handleSetBaseUrl}
                onKeyDown={handleKeyDown}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={handleRefresh}>
                                <Refresh />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
            <WebServiceTable />
            {!ws.webServices && callStatusStore.lastError &&
                <LastErrorAlert title="Error loading web services"/>
            }
        </PageLayout>
    )
}

export default observer(HomePage)
