import { IconButton, Tooltip } from "@material-ui/core"
import { Star, StarBorder } from "@material-ui/icons"
import React from "react"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../contexts"

function FavoritesButton() {
    const { optionsStore } = useRootStore()

    const handleClick = () => {
        optionsStore.setFavoriteTab("Favorites")
        optionsStore.setFavorites(!optionsStore.showFavorites)
    }

    return (
        <Tooltip title="Show favorites">
            <IconButton
                color="inherit"
                aria-label="favorites"
                onClick={handleClick}
            >
                {optionsStore.showFavorites ? <StarBorder /> : <Star />}
            </IconButton>
        </Tooltip>
    )
}

export default observer(FavoritesButton)
