import PageLayout from "../../components/PageLayout"
import React from "react"
import SettingsCard from "./SettingsCard"
import { observer } from "mobx-react"

function SettingsPage() {
    return (
        <PageLayout title="Settings">
            <SettingsCard />
        </PageLayout>
    )
}

export default observer(SettingsPage)
