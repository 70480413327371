import Alert from "@material-ui/lab/Alert"
import React from "react"
import Snackbar from "@material-ui/core/Snackbar"

declare type MessageType =
    "success"
    | "warning"
    | "error"
    | "info"

export interface IProps {
    severity: MessageType
    message?: React.ReactElement | string
    action?: React.ReactElement | string
    onClose: () => void
    onClick?: () => void
}

export function PopupMessage({ severity, message, action, onClose, onClick }: IProps) {
    if (!message) {
        return null
    }

    return (
        <Snackbar open={true} autoHideDuration={10000} onClose={onClose} onClick={onClick}>
            <Alert
                onClose={onClose}
                severity={severity}
                elevation={6}
                variant="filled"
                action={action}
            >
                {message}
            </Alert>
        </Snackbar>
    )
}
