// Maps the supported routes to the application pages

import ErrorPage from "../pages/ErrorPage"
import HomePage from "../pages/HomePage"
import MessageLogPage from "../pages/MessageLogPage"
import NotFoundPage from "../pages/NotFoundPage"
import React from "react"
import SettingsPage from "../pages/SettingsPage"
import WebServicePage from "../pages/WebServicePage"

export const viewMap = {
    home: <HomePage />,
    appServer: <HomePage />,
    login: <NotFoundPage />,
    settings: <SettingsPage />,
    restService: <WebServicePage />,
    webSocketService: <WebServicePage />,
    messageLog: <MessageLogPage />,
    lastError: <ErrorPage />,
    notFound: <NotFoundPage />,
}
