import { apiClient } from "./request"

export interface WebServiceInfo {
    ID: number
    Name: string
    GroupName: string
    RequestParameterCount: number
    ResponseParameterCount: number
    DevComments: string
    IsWebSocketService: boolean
    IsArrayResponse: boolean
    DtoTypes: WebServiceDto[]
    SampleJsonRequest: string
    SampleJsonResponse: string
    JsonRequestUrl: string
    JsonRequestStructure: string
}

export interface WebServiceDto {
    Name: string
    IsResponse: boolean
    Parameters: WebServiceParameter[]
}

export interface WebServiceParameter {
    ID: number
    ParentID: number
    Name: string
    Type: string
    IsArray: boolean
    IsResponse: boolean
    DisplayType: string
    DevComments: string
}

export async function GetWebServiceMetadata(): Promise<WebServiceInfo[]>;
export async function GetWebServiceMetadata(name: string, webSocket?: boolean): Promise<WebServiceInfo>;
export async function GetWebServiceMetadata(name?: string, webSocket?: boolean): Promise<WebServiceInfo[] | WebServiceInfo> {
    const response = await apiClient.get("metadata", {
        params: {
            Name: name,
            WebSocketService: webSocket,
        }
    })

    if (response.statusText !== "OK") {
        console.log("Got a response that's not OK")
        throw new Error("Oops!" + response.statusText)
    }

    if (name) {
        // work around the old version of the backend service
        const wsInfo = response.data as WebServiceInfo
        if (wsInfo.IsWebSocketService === undefined) {
            wsInfo.IsWebSocketService = false
        }

        return wsInfo
    }

    return response.data as WebServiceInfo[]
}
