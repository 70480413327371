import { Button, Card, CardContent, TextField } from "@material-ui/core"
import React, { useState } from "react"
import { HorizontalContainer } from "@react-force/core"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../contexts"

function SettingsCard() {
    const { settingsStore } = useRootStore()
    const {
        apiUrl,
        apiUser,
        apiKey,
        apiTimeout,
        wsKey,
        wsUrl,
        wsUser,
        wsVersion,
    } = settingsStore.settings

    const [timeout, setTimeout] = useState(apiTimeout)
    const [state, setState] = useState({
        apiUrl,
        apiUser,
        apiKey,
        wsUrl,
        wsUser,
        wsKey,
        wsVersion,
    })

    const handleStateChange = (key: keyof typeof state) =>
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const s = { ...state }
            s[key] = event.currentTarget.value
            setState(s)
        }

    const handleTimeoutChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let t = parseInt(event.currentTarget.value, 10) || 5000
        t = t < 0 ? 0 : (t > 60000 ? 60000 : t)
        setTimeout(t)
    }

    const handleResetClick = () => {
        const {
            apiUrl,
            apiUser,
            apiKey,
            apiTimeout,
            wsKey,
            wsUrl,
            wsUser,
            wsVersion,
        } = settingsStore.reset()

        setTimeout(apiTimeout)
        setState({
            apiUrl,
            apiUser,
            apiKey,
            wsKey,
            wsUser,
            wsUrl,
            wsVersion,
        })
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.stopPropagation()
        event.preventDefault()
        settingsStore.set({
            apiUrl: state.apiUrl,
            apiUser: state.apiUser,
            apiKey: state.apiKey,
            apiTimeout: timeout,
            wsKey: state.wsKey,
            wsUrl: state.wsUrl,
            wsUser: state.wsUser,
            wsVersion: state.wsVersion,
        })

        settingsStore.saveToStorage()
    }

    return (
        <Card variant="outlined">
            <CardContent>
                <form onSubmit={handleSubmit}>
                    <HorizontalContainer>
                        <div style={{ padding: "1em", flex: "1" }}>
                            <TextField value={state.apiUrl} onChange={handleStateChange("apiUrl")} fullWidth={true} margin="dense"
                                label="Web server"
                            />

                            <TextField value={timeout} onChange={handleTimeoutChange} fullWidth={true} margin="dense"
                                label="Timeout"
                            />

                            <TextField value={state.apiUser} onChange={handleStateChange("apiUser")} fullWidth={true} margin="dense"
                                label="Web app (user name)"
                            />

                            <TextField value={state.apiKey} onChange={handleStateChange("apiKey")} fullWidth={true} type="password" margin="dense"
                                label="Web key (password)"
                            />
                        </div>
                        <div style={{ padding: "1em", flex: "1" }}>
                            <TextField value={state.wsUrl} onChange={handleStateChange("wsUrl")} fullWidth={true} margin="dense"
                                label="Web socket server"
                            />

                            <TextField value={state.wsVersion} onChange={handleStateChange("wsVersion")} fullWidth={true} margin="dense"
                                label="Kernel version"
                            />

                            <TextField value={state.wsUser} onChange={handleStateChange("wsUser")} fullWidth={true} margin="dense"
                                label="User name"
                            />

                            <TextField value={state.wsKey} onChange={handleStateChange("wsKey")} fullWidth={true} type="password" margin="dense"
                                label="Password"
                            />
                        </div>
                    </HorizontalContainer>

                    <div style={{ margin: "1em" }}>
                        <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                        >
                            Apply changes
                        </Button>
                        <Button
                            variant="contained"
                            type="button"
                            color="secondary"
                            onClick={handleResetClick}
                            style={{ marginLeft: "1em" }}
                        >
                            Reset to defaults
                        </Button>
                    </div>
                </form>
            </CardContent>
        </Card>
    )
}

export default observer(SettingsCard)
