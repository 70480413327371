// Represents a simple DTO object input form of several parameter boxes

import { WebServiceDto, WebServiceParameter } from "../../services/GetWebServices"
import React from "react"
import { createParameterBox } from "./ParameterBoxHelper"

interface IProps {
    name: string
    value: { [key: string]: any } | null
    update: (name: string, value: Object | null) => void
    structure: WebServiceDto[]
    dtoClass: WebServiceDto | null
}

function ParameterForm(props: IProps) {
    const createInputBox = (param: WebServiceParameter) => {
        const handleUpdate = (name: string, value: { [key: string]: any } | null) => {
            // make sure that the value is an object
            let newValue: any = props.value || {} // { ...props.value };
            if (typeof props.value !== "object") {
                newValue = {}
            }

            newValue[name] = value

            // update the value
            if (props.update) {
                let formName = props.name
                props.update(formName, newValue)
            }
        }

        // avoid trying to get a null's property
        var paramValue: Object | null = null
        if (props.value != null) {
            paramValue = props.value[param.Name]
        }

        return createParameterBox(props.structure, param, paramValue, handleUpdate)
    }

    // render
    if (props.structure == null || props.structure.length === 0 || props.dtoClass == null) {
        return <h2>No input parameters</h2>
    }

    const dto = props.dtoClass
    const params = dto.Parameters.map(param => createInputBox(param))

    return (
        <>
            {params}
        </>
    )
}

export default ParameterForm
