import "./editor.css"
import "./prism.css"
import React, { CSSProperties } from "react"
import { highlight, languages } from "prismjs/components/prism-core"
import Editor from "react-simple-code-editor"
// import Prism from "prismjs"

// eslint-disable-next-line sort-imports
import "prismjs/components/prism-clike"
import "prismjs/components/prism-javascript"
import "prismjs/components/prism-json"

interface IProps {
    json?: string
    onJsonChange?: (json: string) => void
    style?: CSSProperties
}

function JsonEditor({ json, onJsonChange }: IProps) {
    const handleChange = (s: string) => {
        if (onJsonChange) {
            onJsonChange(s)
        }
    }

    return (
        <Editor
            value={json || ""}
            onValueChange={handleChange}
            highlight={code => highlight(code, languages.js)}
            padding={10}
            style={{
                outline: "none",
                fontFamily: "'Fira code', 'Fira Mono', monospace",
                fontSize: 14,
            }}
        />
    )
}

export default JsonEditor
