import { IconButton, Tooltip } from "@material-ui/core"
import { Star, StarOutline } from "@material-ui/icons"
import React from "react"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../contexts"

interface IProps {
    name: string
    isWebSocket?: boolean
}

function FavoriteButton({ name, isWebSocket }: IProps) {
    const { favoritesStore: fs } = useRootStore()
    const ws = isWebSocket || false

    const isFavorite = fs.isFavorite(name, ws)
    const tooltip = isFavorite ? "Click to remove from favorites" : "Click to add favorite"
    const handleToggleFavorite = () =>
        isFavorite ? fs.removeFavorite(name, ws) : fs.addFavorite(name, ws)

    return (
        <Tooltip title={tooltip}>
            <IconButton onClick={handleToggleFavorite}>
                {isFavorite ?
                    <Star /> :
                    <StarOutline />
                }
            </IconButton>
        </Tooltip>
    )
}

export default observer(FavoriteButton)
