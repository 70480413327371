import React, { useState } from "react"
import { TabContext, TabList, TabPanel } from "@material-ui/lab"
import JsonViewer from "../components/JsonViewer"
import PageLayout from "../components/PageLayout"
import { Tab } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../contexts"

function ErrorPage() {
    const { lastError } = useRootStore().callStatusStore
    const [tab, setTab] = useState("stack")
    const handleChangeTab = (e: unknown, tab: "stack" | "properties") =>
        setTab(tab)

    const message = lastError?.message
    const title = "Error" + (message ? `: ${message}` : "")

    // deep clone and shorten the call stack
    const error = JSON.parse(JSON.stringify(lastError || ""))
    const callStack = `${error.stack}`
    if (callStack?.length > 40) {
        error.stack = callStack.substring(0, 40) + "..."
    }

    const json = JSON.stringify(error, null, 2)

    return (
        <PageLayout title={title}>
            <TabContext value={tab}>
                <TabList onChange={handleChangeTab}>
                    <Tab label="Call stack" value="stack"/>
                    <Tab label="Error properties" value="properties"/>
                </TabList>
                <TabPanel value="stack" style={{ paddingLeft: 0, paddingRight: 0, paddingTop: "1em" }}>
                    {lastError?.stack && <JsonViewer json={lastError.stack}/>}
                </TabPanel>
                <TabPanel value="properties" style={{ paddingLeft: 0, paddingRight: 0, paddingTop: "1em" }}>
                    <JsonViewer json={json} />
                </TabPanel>
            </TabContext>
        </PageLayout>
    )
}

export default observer(ErrorPage)
