export function stringifyValue(value: Object | null | undefined) {
    // if an object has just one scalar property, don't indent it
    // to make sure that a single value fits in a single-line textarea
    let formattedJson = JSON.stringify(value, null, 2)

    // simple heuristic: if the indented JSON has 3 lines, then the value has single scalar property
    let lines = formattedJson.split(/\r\n|\r|\n/)
    if (lines.length === 3) {
        return lines[0] + " " + lines[1].trim() + " " + lines[2]
    }

    // too bad we don't use these cool checks:
    // if (Object.keys(obj).length == 1) <- obj has only one property
    // if (obj === Object(obj)) <- obj is non-scalar
    return formattedJson
}
