import { ArrowBack } from "@material-ui/icons"
import { IconButton } from "@material-ui/core"
import React from "react"
import { browserHistory } from "mobx-state-router"

function BackButton() {
    const handleClick = () => browserHistory.goBack()

    return (
        <IconButton
            edge="start"
            color="inherit"
            onClick={handleClick}
        >
            <ArrowBack />
        </IconButton>
    )
}

export { BackButton }
