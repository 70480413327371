// Represents a single array parameter input control

import { Button, Grid } from "@material-ui/core"
import { WebServiceDto, WebServiceParameter } from "../../services/GetWebServices"
import ParameterPanel from "./ParameterPanel"
import React from "react"
import { createParameterBox } from "./ParameterBoxHelper"
import { useMountEffect } from "../../utils/useMountEffect"

interface IProps {
    name: string
    type: string
    isDto: boolean
    value: Object | null
    update: (name: string, value: Object | null) => void
    param: WebServiceParameter
    structure: WebServiceDto[]
}

function ArrayParameterBox(props: IProps) {
    const getValue = () => props.value instanceof Array ? props.value : []
    const updateValue = (value: Object | null) => {
        if (props.update) {
            props.update(props.name, value)
        }
    }

    // initialize the array when mounted
    useMountEffect(() => updateValue(getValue()))

    const appendElement = (newValue: Object | null) => {
        let array = getValue().concat([ newValue ])
        updateValue(array)
    }

    const deleteElement = (index: number) => {
        let newArray = getValue().slice()
        newArray.splice(index, 1)
        updateValue(newArray)
    }

    const clearElements = () => updateValue([])
    const handleClickClear = () => clearElements()
    const handleClickPlus = () => appendElement(null)

    const createParamBox = (param: WebServiceParameter, index: number) => {
        // replaces an element within the array
        function handleUpdate(name: string, value: Object | null) {
            let array = getValue().slice()
            array[index] = value
            updateValue(array)
        }

        return createParameterBox(props.structure, param, getValue()[index], handleUpdate, false)
    }

    // TODO: type parameterForm
    const decorateDtoElement = (index: number, parameterForm: any, removeItemHandler: () => void) => {
        return (
            <ParameterPanel title={props.type} linkText={"Remove item " + index} linkHandler={removeItemHandler}>
                {parameterForm}
            </ParameterPanel>
        )
    }

    // TODO: type ParameterBox as React.ReactElement
    const decorateScalarElement = (index: number, parameterBox: any, removeItemHandler: () => void) => {
        /*const deleteClickHandler = () => {
            if (removeItemHandler) {
                removeItemHandler()
            }
        }

        // TODO: add these props to the parameter box
        const inputProps = {
            // startAdornment: (
            //     <InputAdornment position="start">
            //         <small>
            //             0:
            //         </small>
            //     </InputAdornment>
            // ),
            endAdornment: (
                <InputAdornment position="end">
                    <IconButton onClick={deleteClickHandler} title={`Remove item ${index}`}>
                        <Delete />
                    </IconButton>
                </InputAdornment>
            )
        }*/

        return parameterBox
        //return <ParameterBox label={index} InputProps={inputProps} />
    }

    const decorateElement = (index: number, element: Object | null) => {
        // link event handler to remove an item
        let removeItemHandler = () => deleteElement(index)

        let result =
            props.isDto ?
                decorateDtoElement(index, element, removeItemHandler) :
                decorateScalarElement(index, element, removeItemHandler)

        return result
    }

    const param = props.param
    const params = getValue().map((p, index) => (
        <React.Fragment key={index}>{
            decorateElement(index, createParamBox(param, index))
        }
        </React.Fragment>
    ))

    // TODO: type params as React.ReactElement
    return (
        <ParameterPanel title={props.name}>
            {params as any}
            <Grid container spacing={1} style={{ marginTop: "1em" }}>
                <Grid item>
                    <Button variant="outlined" color="primary" onClick={handleClickPlus}>
                        Add item
                    </Button>
                </Grid>
                <Grid item>{
                    (params.length === 0) ?
                        <div style={{ marginLeft: 0, marginTop: "0.5em" }}>← Click to add a new value</div> :
                        <Button variant="outlined" color="secondary"  onClick={handleClickClear}>
                            Remove all items
                        </Button>
                }
                </Grid>
            </Grid>
        </ParameterPanel>
    )
}

export default ArrayParameterBox
