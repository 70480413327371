import "./App.css"
import { ErrorBoundary, Loading } from "@react-force/core"
import React, { Suspense }  from "react"
import { RouterContext, RouterView } from "mobx-state-router"
import CssBaseline from "@material-ui/core/CssBaseline"
import { RootStoreContext } from "./contexts"
import { initApp } from "./initApp"
import { viewMap } from "./stores/viewMap"

// initialize the application
const rootStore = initApp()

function App() {
    const { routerStore } = rootStore

    return (
        <ErrorBoundary>
            <Suspense fallback={<Loading />}>
                <RootStoreContext.Provider value={rootStore}>
                    <RouterContext.Provider value={routerStore}>
                        <CssBaseline />
                        <RouterView viewMap={viewMap} />
                    </RouterContext.Provider>
                </RootStoreContext.Provider>
            </Suspense>
        </ErrorBoundary>
    )
}

export default App
