import { RootStore } from "./RootStore"
import { apiClient } from "../services/request"
import { makeAutoObservable } from "mobx"
import { stringifyValue } from "../utils/stringifyValue"

export class WebServiceRunnerStore {
    rootStore: RootStore
    requestValue: Object | null = null
    responseValue: Object | null = null
    defaultRequestJson?: string
    defaultResponseJson?: string
    requestJson?: string
    responseJson?: string
    responseHeaders?: Object | null = null
    lastFetchTime?: number

    constructor (rootStore: RootStore) {
        makeAutoObservable(this)
        this.rootStore = rootStore
    }

    setRequestValue = (request: Object | null) =>
        this.requestValue = request

    clearRequestValue = () =>
        this.requestValue = null

    setRequestJson = (request: string) =>
        this.requestJson = request

    clearRequestJson = () =>
        this.requestJson = undefined

    setDefaultRequestJson = (defaultRequest: string) => {
        this.defaultRequestJson = defaultRequest
        // this.requestValue = this.parseAndCleanup(defaultRequest)
        // this.requestJson = stringifyValue(this.requestValue)
        this.resetToDefaults()
    }

    clearDefaultRequestJson = () =>
        this.requestJson = this.defaultRequestJson = undefined

    setResponseValue = (response: Object | null) =>
        this.responseValue = response

    clearResponseValue = () =>
        this.responseValue = null

    setResponseJson = (response: string) =>
        this.responseJson = response

    clearResponseJson = () =>
        this.responseJson = undefined

    setDefaultResponseJson = (defaultResponse: string) =>
        this.responseJson = this.defaultResponseJson = defaultResponse

    clearDefaultResponse = () =>
        this.responseJson = this.defaultResponseJson = undefined

    resetToDefaults = () => {
        const requestValue = this.parseAndCleanup(this.defaultRequestJson)
        this.requestJson = stringifyValue(requestValue)
        this.requestValue = requestValue
        this.responseJson = this.defaultResponseJson
        this.clearResponseHeaders()
        this.clearLastFetchTime()
    }

    setResponseHeaders = (headers?: Object | null) =>
        this.responseHeaders = headers

    clearResponseHeaders = () =>
        this.responseHeaders = undefined

    setLastFetchTime = (ms: number) =>
        this.lastFetchTime = ms

    clearLastFetchTime = () =>
        this.lastFetchTime = undefined

    cleanup = (value: { [k: string]: any } | null) => {
        if (!value) {
            return
        }

        if (Array.isArray(value)) {
            value.splice(0, value.length)
        } else if (typeof value === "object") {
            for (const p in value) {
                const v = value[p]
                if (typeof v === "object") {
                    this.cleanup(v)
                } else {
                    value[p] = null
                }
            }
        }
    }

    parseAndCleanup = (json?: string | null) => {
        const parsed = json ? JSON.parse(json) : null
        this.cleanup(parsed)
        return parsed
    }

    execute = async (method: string, url: string) => {
        const requestTime = (new Date()).getTime()

        try {
            const response = await apiClient.request({
                url,
                method: method as any,
                params: this.requestValue,
                data: this.requestValue,
            })

            this.setResponseValue(response)
            this.clearResponseJson()
            this.clearResponseHeaders()

            if (response.data) {
                const responseJson = stringifyValue(response.data)
                this.setResponseJson(responseJson)
            }

            if (response.headers) {
                this.setResponseHeaders(response.headers)
            }
        } catch {
            // the error is handled by axios interceptor
            // so we just ignore it
        } finally {
            const responseTime = (new Date()).getTime()
            this.setLastFetchTime(responseTime - requestTime)
        }
    }
}
