// Helper function to create parameter boxes for the different parameter types

import { WebServiceDto, WebServiceParameter } from "../../services/GetWebServices"
import ArrayParameterBox from "./ArrayParameterBox"
import ParameterForm from "./ParameterForm"
import ParameterPanel from "./ParameterPanel"
import React from "react"
import ScalarParameterBox from "./ScalarParameterBox"

// helper function to create an input control for the given parameter
export function createParameterBox(
    structure: WebServiceDto[],
    parameter: WebServiceParameter,
    value: Object | null,
    handleUpdate: (name: string, value: Object | null) => void,
    wrapDtoPanel: boolean = true): React.ReactElement {

    // check for non-scalar parameter
    var dtoClass: WebServiceDto | null = null
    var isDto = false
    for (var dto of structure) {
        if (dto.Name === parameter.Type) {
            dtoClass = dto
            isDto = true
        }
    }

    // React doesn't allow null values for the components
    var defaultValue: Object | null = ""

    // array type is rendered as an array box
    if (parameter.IsArray) {
        // element type
        var paramElement = { ...parameter, Name: "", IsArray: false }

        // default value is an empty array
        defaultValue = []

        return (
            <ArrayParameterBox
                key={parameter.Name}
                name={parameter.Name}
                type={parameter.Type}
                isDto={isDto}
                param={paramElement}
                structure={structure}
                value={value || defaultValue}
                update={handleUpdate}
            />
        )
    }

    // non-scalar type is rendered as a sub-form
    if (isDto && dtoClass != null) {
        // default value is an empty object
        defaultValue = {}

        if (wrapDtoPanel) {
            return (
                <ParameterPanel key={parameter.Name} title={dtoClass.Name}>
                    <ParameterForm
                        structure={structure}
                        dtoClass={dtoClass}
                        name={parameter.Name}
                        value={value || defaultValue}
                        update={handleUpdate}
                    />
                </ParameterPanel>
            )
        } else {
            return (
                <ParameterForm
                    structure={structure}
                    dtoClass={dtoClass}
                    name={parameter.Name}
                    value={value || defaultValue}
                    update={handleUpdate}
                />
            )
        }
    }

    // avoid collapsing zeros: value || defaultValue
    if (value === null || value === undefined) {
        value = value || defaultValue
    }

    // scalar type is rendered as an input box
    return (
        <ScalarParameterBox
            key={parameter.Name}
            name={parameter.Name}
            type={parameter.Type}
            value={value}
            update={handleUpdate}
        />
    )
}
