import "./prism.css"
import React, { CSSProperties, useEffect } from "react"
import Prism from "prismjs"

// eslint-disable-next-line sort-imports
import "prismjs/components/prism-core"
import "prismjs/components/prism-json"

interface IProps {
    json?: string
    style?: CSSProperties
}

function JsonViewer(props: IProps) {
    useEffect(() => {
        Prism.highlightAll()
    })

    return (
        <pre style={props.style}>
            <code className="language-js">
                {props.json || false}
            </code>
        </pre>
    )
}

export default JsonViewer
