import { IReturn } from "json-services-client"
import { RootStore } from "./RootStore"
import { jsonClient } from "../services/webSockets"
import { makeAutoObservable } from "mobx"
import { stringifyValue } from "../utils/stringifyValue"

function createRequest(serviceName: string, requestValue: any) {
    class Request implements IReturn<any> {
        constructor () {
            Object.assign(this, requestValue)
        }

        getTypeName() {
            return serviceName
        }

        createResponse() {
            return {}
        }
    }

    return new Request()
}

export class WebSocketServiceRunnerStore {
    rootStore: RootStore
    requestValue: Object | null = null
    responseValue: Object | null = null
    defaultRequestJson?: string
    defaultResponseJson?: string
    requestJson?: string
    responseJson?: string
    responseHeaders?: Object | null = null
    lastFetchTime?: number

    constructor (rootStore: RootStore) {
        makeAutoObservable(this)
        this.rootStore = rootStore
    }

    private get rs() {
        return this.rootStore.webServiceRunnerStore
    }

    // redirecting calls to web service runner store
    setResponseValue = (response: Object | null) => this.rs.setResponseValue(response)
    setResponseJson = (response: string) => this.rs.setResponseJson(response)
    clearResponseJson = () => this.rs.clearResponseJson()
    setLastFetchTime = (ms: number) => this.rs.setLastFetchTime(ms)

    execute = async () => {
        const requestTime = (new Date()).getTime()
        const { stack } = new Error()

        try {
            const name = this.rootStore.webServiceStore.webService?.Name ?? "Version"
            const request = createRequest(name, this.rs.requestValue)
            const response = await jsonClient.call(request)

            this.rootStore.callStatusStore.clearLastError()
            this.setResponseValue(response)
            this.clearResponseJson()

            if (response) {
                const responseJson = stringifyValue(response)
                this.setResponseJson(responseJson)
            }
        } catch (e: any) {
            // TODO: intercept calls and errors
            console.log(e)
            const error = new Error(`${e?.data?.message || ""}`)
            Object.assign(error, e)
            error.stack = stack + `\n\nApplication server stack trace:\n${e?.data?.StackTrace}`
            this.rootStore.callStatusStore.setLastError(error)
        } finally {
            const responseTime = (new Date()).getTime()
            this.setLastFetchTime(responseTime - requestTime)
        }
    }
}
