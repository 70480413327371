import { TableCell, TableRow } from "@material-ui/core"
import FavoriteButton from "../../components/FavoriteButton"
import React from "react"
import WebServiceLink from "../../components/WebServiceLink"
import WebServiceTypeIndicator from "../../components/WebServiceTypeIndicator"
import { convertNewLines } from "../../utils/convertNewLines"
import { observer } from "mobx-react"

interface IProps {
    name: string
    groupName: string
    devComments: string
    isWebSocket: boolean
    reqCount: number
    resCount: number
    isArray: boolean
}

function WebServiceTableRow({
    name,
    groupName,
    devComments,
    isWebSocket,
    reqCount,
    resCount,
    isArray
}: IProps) {

    return (
        <TableRow>
            <TableCell>
                <WebServiceLink
                    name={name}
                    isWebSocket={isWebSocket}
                    trackScrollPosition={true}
                />
            </TableCell>
            <TableCell align="center">
                <FavoriteButton
                    name={name}
                    isWebSocket={isWebSocket}
                />
                <WebServiceTypeIndicator
                    isWebSocket={isWebSocket}
                    requestParameters={reqCount}
                    responseParameters={resCount}
                    arrayResponse={isArray}
                />
            </TableCell>
            <TableCell>{groupName}</TableCell>
            <TableCell>{convertNewLines(devComments)}</TableCell>
        </TableRow>
    )
}

export default observer(WebServiceTableRow)
