import { IconButton, Menu, MenuItem } from "@material-ui/core"
import React, { useState } from "react"
import { MoreVert } from "@material-ui/icons"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../contexts"

function HeaderMenu() {
    const { callStatusStore, routerStore } = useRootStore()
    const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)

    const handleMenuOpen = (e: React.MouseEvent<HTMLElement>) =>
        setAnchorElement(e.currentTarget)

    const handleMenuClose = () =>
        setAnchorElement(null)

    const handleGoHome = () => {
        handleMenuClose()
        routerStore.goTo("home")
    }

    const handleSettings = () => {
        handleMenuClose()
        routerStore.goTo("settings")
    }

    const handleShowLastError = () => {
        handleMenuClose()
        routerStore.goTo("lastError")
    }

    const route = routerStore.getCurrentRoute()
    const isHome = route && route.name === "home"

    return (
        <>
            <IconButton
                edge="end"
                onClick={handleMenuOpen}
                color="inherit"
            >
                <MoreVert />
            </IconButton>

            <Menu
                open={anchorElement !== null}
                anchorEl={anchorElement}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={handleSettings}>
                    Settings
                </MenuItem>

                {callStatusStore.lastError &&
                    <MenuItem onClick={handleShowLastError}>
                        Show last error
                    </MenuItem>}

                {!isHome &&
                    <MenuItem onClick={handleGoHome}>
                        Web service list
                    </MenuItem>}
            </Menu>
        </>
    )
}

export default observer(HeaderMenu)
