import { apiKey, apiTimeout, apiUrl, apiUser } from "./constants"
import { appendCallStack, getCallStack } from "../utils/errorUtils"
import axios from "axios"

const axiosSettings = {
    baseURL: apiUrl,
    auth: {
        username: apiUser,
        password: apiKey,
    },
    withCredentials: true,
    timeout: apiTimeout,
    headers: {
        "Content-Type": "application/json;charset=utf-8",
    },
}

// API client instance
export let apiClient = axios.create(axiosSettings)

// a function to modify the client instance
export const updateApiSettings = (settings: {
    apiUrl: string
    apiUser: string
    apiKey: string
    apiTimeout: number
}) => {
    console.log("api client has been recreated", settings)

    const interceptors = apiClient.interceptors
    apiClient = axios.create({
        ...axiosSettings,
        baseURL: settings.apiUrl,
        auth: {
            username: settings.apiUser,
            password: settings.apiKey,
        },
        timeout: settings.apiTimeout,
    })

    // reattach existing interceptors, if any
    apiClient.interceptors.request = interceptors.request
    apiClient.interceptors.response = interceptors.response
}

export interface ILoadingInterceptors {
    startLoading: () => void
    stopLoading: () => void
    clearLastError: () => void
    setLastError: (error: any) => void
}

// sets up loading spinner interceptors
export const setupLoadingInterceptors = (callbacks: ILoadingInterceptors) => {
    console.log("api client interceptors are created", callbacks)

    // start spinning on request
    apiClient.interceptors.request.use(config => {
        console.log(`-> ${config.method}: ${config.url}`)

        // save original call stack
        const c = config as any
        c.originalCallStack = getCallStack()

        callbacks.startLoading()
        callbacks.clearLastError()
        return config
    }, error => {
        callbacks.setLastError(error)
        return Promise.reject(error)
    })

    // stop spinning on response
    apiClient.interceptors.response.use(response => {
        console.log(`<- ${response.config.method}: ${response.config.url}`)
        callbacks.stopLoading()
        callbacks.clearLastError()
        return response
    }, error => {

        // restore the original call stack
        const { originalCallStack } = error.config
        appendCallStack(error, originalCallStack)
        error.config.originalCallStack = undefined

        callbacks.stopLoading()
        callbacks.setLastError(error)
        return Promise.reject(error)
    })
}
