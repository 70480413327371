import { makeAutoObservable, toJS } from "mobx"
import { RootStore } from "./RootStore"

export interface IServiceItem {
    name: string
    isWebSocket: boolean
}

const defaultFavorites = "GetNow,CreateReserve,CreateAgent,CreateDeliveryAddress".split(",").map(s => ({
    name: s,
    isWebSocket: false,
}))

export class FavoritesStore {
    rootStore: RootStore
    favorites: IServiceItem[] = defaultFavorites

    constructor (rootStore: RootStore) {
        makeAutoObservable(this)
        this.rootStore = rootStore
        this.loadFavorites()
    }

    saveFavorites = () => {
        const data = JSON.stringify(toJS(this.favorites))
        localStorage.setItem("webService.favorites", data)
    }

    loadFavorites = () => {
        const data = localStorage.getItem("webService.favorites")
        if (data) {
            this.favorites = JSON.parse(data) as IServiceItem[]
        }
    }

    addFavorite = (name: string, isWebSocket: boolean) => {
        if (this.isFavorite(name, isWebSocket)) {
            return
        }

        this.favorites.push({
            name,
            isWebSocket,
        })

        this.favorites = this.favorites.sort((a, b) => {
            const [an, bn] = [`${a.isWebSocket ? "!": ""}${a.name}`, `${b.isWebSocket ? "!": ""}${b.name}`]
            return an > bn ? 1 : -1
        })

        this.saveFavorites()
    }

    removeFavorite = (name: string, isWebSocket: boolean) => {
        this.favorites = this.favorites.filter(i => i.name !== name || i.isWebSocket !== isWebSocket)
        this.saveFavorites()
    }

    isFavorite = (name: string, isWebSocket: boolean) =>
        this.favorites.find(i => i.name === name && i.isWebSocket === isWebSocket)

    clearFavorites = () => {
        this.favorites = []
        this.saveFavorites()
    }
}
