import { Button } from "@material-ui/core"
import { PopupMessage } from "../PopupMessage"
import React from "react"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../contexts"

function ErrorPopupMessage() {
    const { callStatusStore, routerStore } = useRootStore()
    const { lastError, lastErrorClosed } = callStatusStore
    if (lastError === undefined || lastErrorClosed) {
        return null
    }

    const handleClose = () =>
        callStatusStore.setLastErrorClosed(true)

    const handleMore = () => {
        callStatusStore.setLastErrorClosed(true)
        routerStore.goTo("lastError")
    }

    return (
        <PopupMessage
            severity="error"
            message={`${callStatusStore.lastError}`}
            onClose={handleClose}
            onClick={handleMore}
            action={
                <Button color="inherit">More...</Button>
            }
        />
    )
}

export default observer(ErrorPopupMessage)
