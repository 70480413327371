import React, { useState } from "react"
import { TabContext, TabList, TabPanel } from "@material-ui/lab"
import JsonViewer from "../../components/JsonViewer"
import RequestForm from "../../components/RequestForm"
import { Tab } from "@material-ui/core"
import WebServiceRunner from "./WebServiceRunner"
import WebSocketServiceRunner from "./WebSocketServiceRunner"
import { formatJson } from "../../utils/formatJson"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../contexts"

type Tabs = "RequestForm" | "SampleBody" | "RequestStructure" | "Authentication"

function WebServiceTabView() {
    const [tab, changeTab] = useState<Tabs>("RequestForm")
    const { webServiceRunnerStore, webServiceStore } = useRootStore()
    const { webService: ws } = webServiceStore
    const { defaultRequestJson: defaultRequest } = webServiceRunnerStore

    const handleChangeTab = (e: unknown, tab: Tabs) =>
        changeTab(tab)

    return (
        <>
            <TabContext value={tab}>
                <TabList onChange={handleChangeTab}>
                    <Tab label="Request form" value="RequestForm"/>
                    <Tab label="Sample request body" value="SampleBody"/>
                    <Tab label="Request structure" value="RequestStructure"/>
                    {/*<Tab label="Authentication" value="Authentication" disabled={true} />*/}
                </TabList>
                <TabPanel value="RequestForm" style={{ paddingLeft: 0, paddingRight: 0, paddingTop: "1em" }}>
                    <RequestForm />
                </TabPanel>
                <TabPanel value="SampleBody" style={{ paddingLeft: 0, paddingRight: 0, paddingTop: "1em" }}>
                    <JsonViewer json={defaultRequest} />
                </TabPanel>
                <TabPanel value="RequestStructure" style={{ paddingLeft: 0, paddingRight: 0, paddingTop: "1em" }}>
                    <JsonViewer json={formatJson(ws?.JsonRequestStructure)} />
                </TabPanel>
                <TabPanel value="Authentication" style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <p>Authentication</p>
                </TabPanel>
            </TabContext>
            {ws?.IsWebSocketService ?
                <WebSocketServiceRunner /> :
                <WebServiceRunner />
            }
        </>
    )
}

export default observer(WebServiceTabView)
