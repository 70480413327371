import { isValidDate } from "./isValidDate"

export type ScalarType =
    | "string"
    | "bool"
    | "boolean"
    | "bool?"
    | "boolean?"
    | "DateTime"
    | "DateTime?"
    | "int"
    | "int?"
    | "long"
    | "long?"
    | "decimal"
    | "decimal?"

export function parseScalarValue(str: string | null, type: ScalarType): Object | null {
    if (type === "string") {
        return str
    }

    if (str == null || str === "" || str === "null") {
        if (type.endsWith("?")) {
            return null
        } else if (type === "bool" || type === "boolean") {
            return false
        } else {
            return null
        }
    }

    if (type === "DateTime" || type === "DateTime?") {
        str = str + ""

        // don't try to parse malformed dates
        if (str.length < 4 || !str.match(/\d{4}(-\d{2}-\d{2}\s?(\d{2}:?){0,3})?/)) {
            return str
        }

        try {
            var date = new Date(str + " UTC")
            if (isValidDate(date)) {
                return date
            }
            return str
        } catch (e) {
            // tslint:disable-next-line:no-console
            console.log("Error parsing date: " + str + ": " + e)
            return str
        }
    }

    if (type.startsWith("int") || type.startsWith("long")) {
        const number = Number.parseInt(str)
        if (!isNaN(number)) {
            return number
        }
    }

    if (type.startsWith("decimal")) {
        if (str.endsWith(".")) {
            return str
        }

        let number = Number.parseFloat(str)
        if (!isNaN(number)) {
            return number
        }
    }

    try {
        return JSON.parse(str)
    } catch (e) {
        return str
    }
}
