import { Box, Button, TextField, Typography } from "@material-ui/core"
import JsonViewer from "../../components/JsonViewer"
import LastErrorAlert from "../../components/LastErrorAlert"
import React from "react"
import SimpleToolbar from "../../components/SimpleToolbar"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../contexts"

function WebSocketServiceRunner() {
    const {
        webServiceRunnerStore: restRunnerStore,
        webSocketServiceRunnerStore: wsRunnerStore,
        webServiceStore,
        routerStore,
    } = useRootStore()

    const { webService: ws } = webServiceStore
    const { responseJson } = restRunnerStore

    const handleRequest = async () => {
        await wsRunnerStore.execute()
    }

    const handleShowLog = () => {
        routerStore.goTo("messageLog")
    }

    return (
        <Box>
            <TextField
                label="Request URL"
                value={ws?.JsonRequestUrl}
                fullWidth={true}
                style={{ marginBottom: "1em" }}
            />
            <SimpleToolbar style={{ marginBottom: "1em" }}>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={restRunnerStore.resetToDefaults}
                >
                    Reset to defaults
                </Button>
                <Button variant="contained" color="primary" onClick={handleRequest}>
                    Execute
                </Button>
                <Button variant="contained" onClick={handleShowLog}>
                    Show Log
                </Button>
                {restRunnerStore.lastFetchTime !== undefined &&
                    <Typography variant="subtitle1" style={{ padding: "0.4em" }}>
                        Last fetch time: {restRunnerStore.lastFetchTime} ms.
                    </Typography>
                }
            </SimpleToolbar>
            <LastErrorAlert title={`${ws?.Name} service failed`} style={{ marginBottom: "1em" }}/>
            <Typography variant="h6">Response</Typography>
            <JsonViewer json={responseJson} />
        </Box>
    )
}

export default observer(WebSocketServiceRunner)
