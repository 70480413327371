import { Box, Button, TextField, Typography } from "@material-ui/core"
import JsonViewer from "../../components/JsonViewer"
import LastErrorAlert from "../../components/LastErrorAlert"
import React from "react"
import SimpleToolbar from "../../components/SimpleToolbar"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../contexts"

function WebServiceRunner() {
    const { webServiceRunnerStore: runnerStore, webServiceStore } = useRootStore()
    const { webService: ws } = webServiceStore
    const { responseJson } = runnerStore

    const handleRequest = (method: string) => async () => {
        if (ws?.JsonRequestUrl) {
            await runnerStore.execute(method, ws?.JsonRequestUrl)
        }
    }

    return (
        <Box>
            <TextField
                label="Request URL"
                value={ws?.JsonRequestUrl}
                fullWidth={true}
                style={{ marginBottom: "1em" }}
            />
            <SimpleToolbar style={{ marginBottom: "1em" }}>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={runnerStore.resetToDefaults}
                >
                    Reset to defaults
                </Button>
                <Button variant="contained" color="primary" onClick={handleRequest("get")}>
                    GET
                </Button>
                <Button variant="contained" color="primary" onClick={handleRequest("post")}>
                    POST
                </Button>
                <Button variant="contained" onClick={handleRequest("put")}>
                    PUT
                </Button>
                <Button variant="contained" onClick={handleRequest("delete")}>
                    DELETE
                </Button>
                {runnerStore.lastFetchTime !== undefined &&
                    <Typography variant="subtitle1" style={{ padding: "0.4em" }}>
                        Last fetch time: {runnerStore.lastFetchTime} ms.
                    </Typography>
                }
            </SimpleToolbar>
            <LastErrorAlert title={`${ws?.Name} service failed`} style={{ marginBottom: "1em" }}/>
            <Typography variant="h6">Response</Typography>
            <JsonViewer json={responseJson} />
        </Box>
    )
}

export default observer(WebServiceRunner)
