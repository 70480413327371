import React, { useContext } from "react"
import { RootStore } from "../stores/RootStore"

export const RootStoreContext = React.createContext<RootStore | undefined>(
    undefined
)

export function useRootStore(): RootStore {
    const rootStore = useContext(RootStoreContext)
    if (rootStore === undefined) {
        throw new Error("useRootStore hook must be used within a RootStoreContext.Provider")
    }

    return rootStore
}
