import { Link, Tooltip } from "@material-ui/core"
import React, { useEffect } from "react"
import { useRootStore } from "../contexts"

interface IWebServiceLinkProps {
    name: string
    isWebSocket: boolean
    trackScrollPosition?: boolean
}

function WebServiceLink(props: IWebServiceLinkProps) {
    const { optionsStore, recentStore, routerStore, webServiceListStore: ws } = useRootStore()
    const spanRef = React.createRef<HTMLSpanElement>()

    // restore the scroll position
    useEffect(() => {
        const [name, isWebSocket] = ws.lastWebService || ["", false]
        if (props.trackScrollPosition && name === props.name && isWebSocket === props.isWebSocket) {
            if (spanRef.current) {
                spanRef.current.scrollIntoView({
                    behavior: "auto",
                    block: "start",
                    inline: "start",
                })

                // scoll once and forget it
                ws.clearLastWebService()
            }
        }
    })

    const handleClick = (e: React.MouseEvent) => {
        e.preventDefault()

        // collapse the Favorites panel
        optionsStore.setFavorites(false)
        recentStore.addRecentItem(props.name, props.isWebSocket)
        if (props.trackScrollPosition) {
            ws.setLastWebService(props.name, props.isWebSocket)
        }

        // navigate to the specified webservice page
        const routeName = props.isWebSocket ? "webSocketService" : "restService"
        routerStore.goTo(routeName, {
            params: {
                name: props.name
            }
        })
    }

    return (
        <Tooltip title={props.isWebSocket ? "WebSocket Service" : "REST service"} >
            <span ref={spanRef}>
                <Link
                    component="button"
                    onClick={handleClick}
                    variant="body1"
                >
                    {props.name}
                </Link>
            </span>
        </Tooltip>
    )
}

export default WebServiceLink
