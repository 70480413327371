import { RootStore } from "./RootStore"
import { makeAutoObservable } from "mobx"

export type FavoriteTab = "Favorites" | "Recent"

export class OptionsStore {
    rootStore: RootStore
    showFavorites = false
    favoriteTab: FavoriteTab = "Favorites"

    setFavorites = (show: boolean) =>
        this.showFavorites = show

    setFavoriteTab = (tab: FavoriteTab) =>
        this.favoriteTab = tab

    constructor (rootStore: RootStore) {
        makeAutoObservable(this)
        this.rootStore = rootStore
    }
}
