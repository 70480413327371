import { IconButton, List, ListItem, ListItemSecondaryAction, ListItemText } from "@material-ui/core"
import { Clear } from "@material-ui/icons"
import { IServiceItem } from "../../stores/FavoritesStore"
import React from "react"
import WebServiceLink from "../WebServiceLink"
import WebServiceTypeIndicator from "../WebServiceTypeIndicator"

export interface IProps {
    items: IServiceItem[]
    deleteItem: (name: string, isWebSocket: boolean) => void
    deleteButtonText?: string
}

export function WebServiceList({ items, deleteItem, deleteButtonText }: IProps) {
    const handleDelete = (name: string, isWebSocket: boolean) => () =>
        deleteItem && deleteItem(name, isWebSocket)

    return (
        <List>
            {items.map(f => (
                <ListItem key={`${f.isWebSocket ? "ws" : "rest"}.${f.name}`}>
                    <ListItemText style={{ marginRight: "1em" }}>
                        <WebServiceTypeIndicator isWebSocket={f.isWebSocket} />
                        &nbsp;
                        <WebServiceLink name={f.name} isWebSocket={f.isWebSocket} />
                    </ListItemText>

                    <ListItemSecondaryAction>
                        <IconButton
                            onClick={handleDelete(f.name, f.isWebSocket)}
                            title={deleteButtonText || "Remove from favorites"}>
                            <Clear />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
            ))}
        </List>
    )
}
