import React, { CSSProperties } from "react"
import { Grid } from "@material-ui/core"

type Elements = React.ReactElement | string | undefined | boolean | Elements[]

interface IProps {
    children: Elements
    spacing?: number
    style?: CSSProperties
}

const SimpleToolbar = (props: IProps) => {
    let { children, spacing, style } = props
    if (!Array.isArray(children)) {
        children = [children]
    }

    return (
        <Grid container spacing={spacing as any || 1} style={style}>
            {children.map((element, index) => (
                <Grid item key={index}>
                    {element}
                </Grid>
            ))}
        </Grid>
    )
}

export default SimpleToolbar
