import { Chip, Tooltip } from "@material-ui/core"
import React from "react"

interface IProps {
    value?: "rest" | "websocket"
    onClick: () => void
}

function WebServiceTypeFilter({ value, onClick }: IProps) {
    const color = value === "websocket" ? "secondary" : (value === "rest" ? "primary" : "default")
    const label = value || "all services"
    const tooltip = `Display ${value || "all"} services`
    return (
        <Tooltip title={tooltip}>
            <Chip
                color={color}
                label={label}
                variant="outlined"
                size="small"
                onClick={onClick}
            />
        </Tooltip>
    )
}

export default WebServiceTypeFilter
