import { Dialog } from "@material-ui/core"
import JsonViewer from "../../components/JsonViewer"
import React from "react"
import { stringifyValue } from "../../utils/stringifyValue"

interface IProps {
    json: string
    open: boolean
    onClose: () => void
}

function MessageView({ json, open, onClose }: IProps) {
    if (!open) {
        return null
    }

    let formatted = json
    try {
        formatted = stringifyValue(JSON.parse(json))
    } catch {
        console.error("Broken JSON-RPC message", json)
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <JsonViewer json={formatted} />
        </Dialog>
    )
}

export default MessageView
