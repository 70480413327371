import { Chip, Tooltip } from "@material-ui/core"
import React from "react"

interface IProps {
    isWebSocket: boolean
    requestParameters?: number
    responseParameters?: number
    arrayResponse?: boolean
}

function WebServiceTypeIndicator({ isWebSocket, requestParameters, responseParameters, arrayResponse: arr }: IProps) {
    const typeName = isWebSocket ? "WebSocket" : "REST"
    const color = isWebSocket ? "secondary" : "primary"
    const req = requestParameters || false
    const res = responseParameters || false
    return (
        <Tooltip title={<>
            {typeName} service<br/>
            {req && <>→ Request parameters: {requestParameters}<br/></>}
            {res && <>← Response parameters: {responseParameters}<br/></>}
            {arr && "← Array response"}
        </>}>
            <Chip
                label={typeName.toLowerCase()}
                color={color}
                variant="outlined"
                size="small"
            />
        </Tooltip>
    )
}

export default WebServiceTypeIndicator
