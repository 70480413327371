import { IconButton, Tooltip } from "@material-ui/core"
import React from "react"
import { Restore } from "@material-ui/icons"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../contexts"

function RecentButton() {
    const { optionsStore } = useRootStore()

    const handleClick = () => {
        optionsStore.setFavoriteTab("Recent")
        optionsStore.setFavorites(!optionsStore.showFavorites)
    }

    return (
        <Tooltip title="Show recent items">
            <IconButton
                color="inherit"
                aria-label="recent"
                onClick={handleClick}
            >
                <Restore />
            </IconButton>
        </Tooltip>
    )
}

export default observer(RecentButton)
